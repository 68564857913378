import React, { useEffect, useState, } from "react";

import AwesomeSlider from 'react-awesome-slider';
//import withCaption from 'react-awesome-slider/dist/captioned';
import 'react-awesome-slider/dist/styles.css';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import config from "../Config/Config.json"
import event1 from '../img/2150951243.jpg'
import fb from '../img/fb.png'
import insta from '../img/insta.png'


import Header from "../common/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { UserProfile } from "../Context/UserContext";
import { convertDateTime, convertTime } from "../admin/Config/CommonFunction";
import { Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";

//const CaptionedSlider = withCaption(AwesomeSlider);

const BlogDetailsScreen = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const {getBlogDetailsById,getBlogDetailsByalias}=UserProfile();
    const [blogDetails,setBlogDetails]=useState('')
    const [blogDate,setBlogDate]=useState('')
    const [eventTime,setEventTime]=useState('')
    const [galleryModal,setGalleryModal]=useState(false)
    const [imagePath,setImagePath]=useState('')


    useEffect(() => {
        let tempAlias=window.location.pathname;
        let replaceSlash=tempAlias.split("/");
        console.log("BLOG DETAILS",replaceSlash)
        window.scrollTo(0, 0)
        getBlogdetails(replaceSlash[2]);
    }
      , []);


    const getBlogdetails=async(alias)=>{
        const resp=await getBlogDetailsByalias(alias)
        console.log("BLOG RESP===>",resp)
        setBlogDetails(resp.data)
        setBlogDate(convertDateTime(resp.data.creationDate))
    }
    const openModal=(path)=>{
        setImagePath(path)
        setGalleryModal(true)
    }
    const closeGalleryModal=()=>{
        setImagePath('')
        setGalleryModal(false)
    }
    return(
      <div>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{blogDetails.metaTitle}</title>
            <meta name="description" content={blogDetails.metaDescription} />
            <link rel="canonical" href={"https://thelighterside.in/"} ></link>
            <meta property="og:title" content={blogDetails.metaTitle} />
            <meta property="og:description" content= {blogDetails.metaDescription}/>
            <meta property="og:image" content="https://thelighterside.in/uploads/banner_images/ca896c1a-8fff-4a90-8160-f69c8eba1a47_BANNER.jpg" />
            <meta property="og:url" content="https://thelighterside.in/" />
            <meta property="og:type" content="website" />
    </Helmet>
     <Header/>

  <div className="global" style={{backgroundColor:'#f6e4c2'}}> 
    
 
  <div className="container container_min_height">
        
        <div className="col-lg-12 events mb-5">
            
            <div className="row d-flex pt-3" style={{flexWrap:'wrap'}}>
                <div className="col-lg-6">
                    <img src={blogDetails.imageName===null?event1:config.FILE_URL+blogDetails.imageName} alt="" style={{width:'100%'}}/>
                </div>
                <div className="col-lg-6" style={{marginTop:'10%'}}>
                    <p className="menuTitle mb-3" >{blogDetails.title}</p>
                    <p className="menuTitle mb-3">{blogDate}</p>
                    <p className="menuDesc" dangerouslySetInnerHTML={{__html: blogDetails.description}}/>
                </div>
            </div>
        </div>
    </div>

    

<Modal
          show={galleryModal}
          onHide={closeGalleryModal}
          backdrop="static"
        >
          <Modal.Header closeButton>
            
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-12 mb-3">
                
                
                
              <img src={imagePath} alt="" style={{width:'100%'}}/>
                

              </div>
            </div>
          </Modal.Body>
          </Modal>
    
    
    

   
    
        
    <div className="footer_dark py-2 px-3">
        <div className=" d-flex align-items-center justify-content-between">
            <h6>The Lighter Side &copy; 2024</h6>
            <div className="d-flex align-items-center justify-content-between">
                <img src={fb} alt="fb" style={{height:30,width:30}}/>
                <img src={insta} alt="insta" style={{height:30,width:30}}/>
            </div>
        </div>
        
    </div>
    
</div>
  </div>
    )
}

export default BlogDetailsScreen;