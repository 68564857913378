import React, {
  createContext,
  useState,
  useContext,
  useEffect,
} from "react";

import Config from "../Config/Config.json"
import axios from "axios";
import { useAuth } from "./AuthContext";

const AdminContext = createContext();
const AdminProvider = ({ children }) => {
  const { authData, authDeatils } = useAuth();
  const [categoryList, setCategoryList] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [comboList, setComboList] = useState([]);
  const [galleryList, setGalleryList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [bannerList, setBannerList] = useState([]);
  const [blogList, setBlogList] = useState([]);
  

  useEffect(() => {
    if (authData === null || authData === undefined || authData === '') {
      console.log('No token available');
    }
    else {
      console.log('token===>',authData);
        getAllCategory();
        getAllMenu();
        getAllCombo();
        getAllGallery();
        getAllEvent();
        getAllContact();
        getAllBanner();
        getAllBlog()
    }
  }
    , [authData]);

  

  const getAllCategory = async () => {
    try {
      // console.log("test cat api :", Config.API_URL + Config.ALL_BOOK_CATEGORY)
      const response = await axios.get(Config.API_URL + Config.ALL_CATEGORY,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authData
          },
        })
        console.log("GET CATEGORY RESP FRM CONTEXT===>",response);
        setCategoryList(response.data.data.length === 0 ? [] : response.data.data)
      return response;
    }
    catch (error) {
      setCategoryList([])
      console.log("category context error : ", error);
    }
  }

  const addCategory = async (args) => {
    try {
      const response = await axios.post(Config.API_URL + Config.ADD_CATEGORY, args,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + authData
          },
        })
      getAllCategory();
      return response;
    }
    catch (error) {
      console.log("ADD_CATEGORY_Error : ", error)
    }
  }

  const updateCategory = async (args) => {
    try {
      const response = await axios.post(Config.API_URL + Config.UPDATE_CATEGORY, args,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + authData
          },
        })
      getAllCategory();
      return response;
    }
    catch (error) {
      console.log("ADD_CATEGORY_Error : ", error)
    }
  }

  
  const activeInactiveCategory = async (id) => {
    try {
      const response = await axios.get(Config.API_URL + Config.ACTIVE_CATEGORY+id,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authData
          },
        })
        getAllCategory()
      return response;
    }
    catch (error) {
      console.log("menu context error : ", error);
    }
  }

  


  const getAllMenu = async () => {
    try {
      const response = await axios.get(Config.API_URL + Config.GET_ALL_MENU,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authData
          },
        })
        setMenuList(response.data.data.length === 0 ? [] : response.data.data)
      return response;
    }
    catch (error) {
      setMenuList([])
      console.log("category context error : ", error);
    }
  }

  const addMenu = async (args) => {
    try {
      const response = await axios.post(Config.API_URL + Config.ADD_MENU, args,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + authData
          },
        })
        getAllMenu();
      return response;
    }
    catch (error) {
      console.log("ADD_MENU_CONTEXT_ERROR : ", error)
    }
  }

  const updateMenu = async (args) => {
    try {
      const response = await axios.post(Config.API_URL + Config.UPDATE_MENU, args,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + authData
          },
        })
        getAllMenu();
      return response;
    }
    catch (error) {
      console.log("ADD_MENU_CONTEXT_ERROR : ", error)
    }
  }
const activeInactiveMenu = async (id) => {
    try {
      const response = await axios.get(Config.API_URL + Config.ACTIVE_MENU+id,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authData
          },
        })
        getAllMenu()
      return response;
    }
    catch (error) {
      console.log("menu context error : ", error);
    }
  }

  const getAllCombo = async () => {
    try {
      const response = await axios.get(Config.API_URL + Config.GET_ALL_COMBO,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authData
          },
        })
        setComboList(response.data.data.length === 0 ? [] : response.data.data)
      return response;
    }
    catch (error) {
      setComboList([])
      console.log("combo context error : ", error);
    }
  }
  const addCombo = async (args) => {
    try {
      const response = await axios.post(Config.API_URL + Config.ADD_COMBO, args,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + authData
          },
        })
        getAllCombo();
      return response;
    }
    catch (error) {
      console.log("ADD_MENU_CONTEXT_ERROR : ", error)
    }
  }
  const updateCombo = async (args) => {
    try {
      const response = await axios.post(Config.API_URL + Config.UPDATE_COMBO, args,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + authData
          },
        })
        getAllCombo();
      return response;
    }
    catch (error) {
      console.log("ADD_MENU_CONTEXT_ERROR : ", error)
    }
  }
  const activeInactiveCombo = async (id) => {
    try {
      const response = await axios.get(Config.API_URL + Config.ACTIVE_COMBO+id,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authData
          },
        })
        getAllCombo()
      return response;
    }
    catch (error) {
      console.log("category context error : ", error);
    }
  }
  const getAllGallery = async () => {
    try {
      const response = await axios.get(Config.API_URL + Config.GET_ALL_GALLERY,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authData
          },
        })
        
        setGalleryList(response.data.data.length === 0 ? [] : response.data.data)
      return response;
    }
    catch (error) {
      setGalleryList([])
      console.log("combo context error : ", error);
    }
  }
  const addGallery=async(args)=>{
    try {
      const response = await axios.post(Config.API_URL + Config.ADD_GALLERY, args,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + authData
          },
        })
        getAllGallery();
      return response;
    }
    catch (error) {
      console.log("ADD_GALLERY_CONTEXT_ERROR : ", error)
    }
  }
  const deleteGallery = async (id) => {
    try {
      const response = await axios.get(Config.API_URL + Config.DELETE_GALLERY+id,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authData
          },
        })
        getAllGallery()
      return response;
    }
    catch (error) {
      console.log("menu context error : ", error);
    }
  }
  const getAllEvent = async () => {
    try {
      const response = await axios.get(Config.API_URL + Config.GET_ALL_EVENT,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authData
          },
        })
        
        setEventList(response.data.data.length === 0 ? [] : response.data.data)
      return response;
    }
    catch (error) {
      setEventList([])
      console.log("event context error : ", error);
    }
  }
  const addEvent=async(args)=>{
    try {
      const response = await axios.post(Config.API_URL + Config.ADD_EVENT, args,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + authData
          },
        })
        getAllEvent();
      return response;
    }
    catch (error) {
      console.log("ADD_GALLERY_CONTEXT_ERROR : ", error)
    }
  }
  const updateEvent = async (args) => {
    try {
      const response = await axios.post(Config.API_URL + Config.UPDATE_EVENT, args,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + authData
          },
        })
        getAllEvent();
      return response;
    }
    catch (error) {
      console.log("ADD_MENU_CONTEXT_ERROR : ", error)
    }
  }
  const activeInactiveEvent = async (id) => {
    try {
      const response = await axios.get(Config.API_URL + Config.ACTIVE_EVENT+id,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authData
          },
        })
        getAllEvent()
      return response;
    }
    catch (error) {
      console.log("event context error : ", error);
    }
  }

  const getAllContact = async () => {
    try {
      const response = await axios.get(Config.API_URL + Config.CONTACT_GET_ALL,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authData
          },
        })
        
        setContactList(response.data.data.length === 0 ? [] : response.data.data)
      return response;
    }
    catch (error) {
      setContactList([])
      console.log("combo context error : ", error);
    }
  }

  const getAllBanner = async () => {
    try {
      const response = await axios.get(Config.API_URL + Config.GET_ALL_BANNER,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authData
          },
        })
        console.log("BANNER LST CONT",response)
        setBannerList(response.data.data.length === 0 ? [] : response.data.data)
      return response;
    }
    catch (error) {
      setBannerList([])
      console.log("combo context error : ", error);
    }
  }
  const addBanner=async(args)=>{
    try {
      const response = await axios.post(Config.API_URL + Config.ADD_BANNER, args,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + authData
          },
        })
        getAllBanner();
      return response;
    }
    catch (error) {
      console.log("ADD_GALLERY_CONTEXT_ERROR : ", error)
    }
  }
  const deleteBanner = async (args) => {
    
    try {
      const response = await axios.post(Config.API_URL + Config.DELETE_BANNER,args,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authData
          },
        })
        getAllBanner()
      return response;
    }
    catch (error) {
      console.log("menu context error : ", error);
    }
  }
  const getAllBlog = async () => {
    try {
      const response = await axios.get(Config.API_URL + Config.GET_ALL_BLOG,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authData
          },
        })
      console.log("BLOG LIST===>",response.data)
      setBlogList(response.data.data.length === 0 ? [] : response.data.data)
      return response;
    }
    catch (error) {
      setBlogList([])
      console.log("BLOG_CONTEXT_ERROR", error);
    }
  }
  const addBlog=async(args)=>{
    try {
      const response = await axios.post(Config.API_URL + Config.ADD_BLOG, args,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + authData
          },
        })
        getAllBlog();
      return response;
    }
    catch (error) {
      console.log("ADD_BLOG_CONTEXT_ERROR : ", error)
    }
  }

  const activeInactiveBlog = async (id) => {
    try {
      const response = await axios.get(Config.API_URL + Config.DELETE_BLOG+id,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + authData
          },
        })
        getAllBlog()
      return response;
    }
    catch (error) {
      console.log("event context error : ", error);
    }
  }
  
  const updateBlog = async (args) => {
    try {
      const response = await axios.post(Config.API_URL + Config.UPDATE_BLOG, args,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + authData
          },
        })
        getAllBlog();
      return response;
    }
    catch (error) {
      console.log("ADD_MENU_CONTEXT_ERROR : ", error)
    }
  }

  return (
    <AdminContext.Provider
      value={{
        getAllCategory,
        categoryList,
        addCategory,
        updateCategory,
        activeInactiveCategory,
        menuList,
        addMenu,
        updateMenu,
        activeInactiveMenu,
        comboList,
        addCombo,
        updateCombo,
        activeInactiveCombo,
        galleryList,
        addGallery,
        deleteGallery,
        eventList,
        addEvent,
        updateEvent,
        activeInactiveEvent,
        contactList,
        bannerList,
        addBanner,
        deleteBanner,
        blogList,
        addBlog,
        activeInactiveBlog,
        updateBlog
        
      }}
    >
      {children}
    </AdminContext.Provider>
  )


}

function AdminProfile() {
  const context = useContext(AdminContext);
  return context
}


export { AdminProvider, AdminProfile, AdminContext }  