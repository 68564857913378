import React, {
  createContext,
  useState,
  useContext,
  useEffect
} from "react";
import Config from "../Config/Config.json"
import axios from "axios";
import LoadingOverlay from 'react-loading-overlay-ts';

const UserContext = createContext();

const UserProvider = ({ children}) => {
const [isActive, setIsActive] = useState(false)
const [allGallery, setAllGallery] = useState([])
const [allEvent, setAllEvent] = useState([])
const [allCombo, setallCombo] = useState([])
const [allCategory,setallCategory] = useState([])
const [allBanner, setAllBanner] = useState([])
const [allBlog, setAllBlog] = useState([])


  useEffect(() => {
    getAllCategory();
    getAllCombo();
    getAllEvent();
    getAllGallery();
    getAllBanner();
    getAllBlog();
    
  },[]);


  const getAllCategory = async () => {
    setIsActive(true)
    try{
      const cat_response = await axios.get(Config.API_URL + Config.GET_ALL_CATEGORY, 
        {
          headers: {
            'Content-Type': 'application/json',
            //'Authorization':'Bearer '+authToken
          },
          
      })
      setallCategory(cat_response.data.data);
      setIsActive(false)
      
    }
    catch(error){
      setallCategory([]);
      setIsActive(false)
      console.log("category context ERROR",error)
    }
  }

  const getAllCombo = async () => {
    setIsActive(true)
    try{
      const response = await axios.get(Config.API_URL + Config.GET_ALL_COMBO, 
        {
          headers: {
            'Content-Type': 'application/json',
          },
          
      })
      setallCombo(response.data.data);
      setIsActive(false)
    }
    catch(error){
      setallCombo([]);
      setIsActive(false)
      console.log("combo context ERROR",error)
    }
  }

  const getAllEvent = async () => {
    setIsActive(true)
    try{
      const response = await axios.get(Config.API_URL + Config.GET_ALL_EVENT, 
        {
          headers: {
            'Content-Type': 'application/json',
          },
          
      })
      setAllEvent(response.data.data);
      setIsActive(false)
    }
    catch(error){
      setAllEvent([]);
      setIsActive(false)
      console.log("event context ERROR",error)
    }
  }

  const getAllGallery = async () => {
    setIsActive(true)
    try{
      const response = await axios.get(Config.API_URL + Config.GET_ALL_GALLERY, 
        {
          headers: {
            'Content-Type': 'application/json',
          },
          
      })
      setAllGallery(response.data.data);
      setIsActive(false)
    }
    catch(error){
      setAllGallery([]);
      setIsActive(false)
      console.log("event context ERROR",error)
    }
  }

  const getAllMenuById = async (id) => {
    setIsActive(true)
    try{
      const response = await axios.get(Config.API_URL + Config.GET_ALL_MENU_BY_CAT_ID+id, 
        {
          headers: {
            'Content-Type': 'application/json',
            //'Authorization':'Bearer '+authToken
          },
          
      })
      setIsActive(false)
      return response.data
    }
    catch(error){
      setIsActive(false)
      console.log("MENU context ERROR",error)
    }
  }
   
  
  const getEventDetailsById = async (id) => {
    setIsActive(true)
    try{
      const response = await axios.get(Config.API_URL + Config.EVENT_BY_ID+id, 
        {
          headers: {
            'Content-Type': 'application/json',
            //'Authorization':'Bearer '+authToken
          },
          
      })
      setIsActive(false)
      return response.data
    }
    catch(error){
      setIsActive(false)
      console.log("MENU context ERROR",error)
    }
  }


  const customerQuery = async (args) => {
    try {
      const response = await axios.post(Config.API_URL + Config.SEND_MAIL, args,
        {
          headers: {
            'Content-Type': 'application/json',
            // 'Authorization': 'Bearer ' + authData
          },
        })
        
      return response;
    }
    catch (error) {
      console.log("SEND_MAIL_CONTEXT_ERROR : ", error)
    }
  }

  const getAllBanner = async () => {
    setIsActive(true)
    try{
      const response = await axios.get(Config.API_URL + Config.GET_ALL_BANNER, 
        {
          headers: {
            'Content-Type': 'application/json',
          },
          
      })
      setAllBanner(response.data.data);
      
    }
    catch(error){
      setAllBanner([]);
      console.log("event context ERROR",error)
    }
  }

  const getAllBlog = async () => {
    
    try{
      const response = await axios.get(Config.API_URL + Config.GET_ALL_BLOG, 
        {
          headers: {
            'Content-Type': 'application/json',
          },
          
      })
      setAllBlog(response.data.data);
      
    }
    catch(error){
      setAllBlog([]);
      console.log("event context ERROR",error)
    }
  }


  const getBlogDetailsById = async (id) => {
    setIsActive(true)
    try{
      const response = await axios.get(Config.API_URL + Config.BLOG_BY_ID+id, 
        {
          headers: {
            'Content-Type': 'application/json',
            //'Authorization':'Bearer '+authToken
          },
          
      })
      setIsActive(false)
      return response.data
    }
    catch(error){
      setIsActive(false)
      console.log("MENU context ERROR",error)
    }
  }


  const getBlogDetailsByalias = async (alias) => {
    setIsActive(true)
    try{
      const response = await axios.get(Config.API_URL + Config.BLOG_BY_ALIAS+alias, 
        {
          headers: {
            'Content-Type': 'application/json',
            //'Authorization':'Bearer '+authToken
          },
          
      })
      setIsActive(false)
      return response.data
    }
    catch(error){
      setIsActive(false)
      console.log("MENU context ERROR",error)
    }
  }






  return (
    <UserContext.Provider
      value={{
        allCategory,
        getAllCategory,
        getAllMenuById,
        allCombo,
        getAllCombo,
        getAllEvent,
        allEvent,
        getAllGallery,
        allGallery,
        getEventDetailsById,
        customerQuery,
        allBanner,
        allBlog,
        getBlogDetailsById,
        getBlogDetailsByalias
        
      }}
    >
      {/* {children} */}
      {/* <ActivityLoader isLoaderShow ={loaderOn}/> */}
      <LoadingOverlay
      //active={isActive}
      active={false}
      spinner
      text='Loading your content...'
    >
      {children}
      </LoadingOverlay>
    </UserContext.Provider>
  )
}
function UserProfile() {
  const context = useContext(UserContext)

  // if (!context) {
  //   throw new Error('userProfile must be used within an userProvider')
  // }

  return context
}
export { UserContext, UserProvider, UserProfile }


