// import './Css.css';
import { BrowserRouter as Router, Route,Routes } from 'react-router-dom';

import React, {
useState,
useEffect
} from "react";
import HomeScreen from './pages/HomeScreen';
import DefaultPage from './admin/DefaultPage';
import MenuScreen from './pages/MenuScreen';
import CategoryScreen from './pages/CategoryScreen';
import EventScreen from './pages/EventScreen';
import GalleryScreen from './pages/GalleryScreen';
import EventDetailsScreen from './pages/EventDetailsScreen';
import ComboScreen from './pages/ComboScreen';
import SplashScreen from './pages/SplashScreen';
import BlogScreen from './pages/BlogScreen';
import BlogDetailsScreen from './pages/BlogDetailsScreen';

const AppRouter=()=> {

  return (
    <Router>
        <Routes>
            <Route exact path="/" Component={SplashScreen}/>
            <Route exact path="/home" Component={HomeScreen}/>
            <Route exact path="/menu" Component={MenuScreen}/>
            <Route exact path="/categories" Component={CategoryScreen}/>
            <Route exact path="/event" Component={EventScreen}/>
            <Route exact path="/gallery" Component={GalleryScreen}/>
            <Route exact path="/eventdetails" Component={EventDetailsScreen}/>
            <Route exact path="/combo" Component={ComboScreen}/>
            <Route exact path="/blog" Component={BlogScreen}/>
            <Route exact path="/blogdetails/:blogalias/" Component={BlogDetailsScreen}/>
            <Route exact path="/admin/*" Component={DefaultPage}/>
        </Routes>
    </Router>
  );
}


export default AppRouter;
